.theme2 {
    --primary-100: #C2C5CC;
    --primary-200: #ACB0BA;
    --primary-300: #969BA7;
    --primary-400: #7F8694;
    --primary-500: #6B7280;
    --primary-600: #515761;
    --primary-700: #383C43;
    --primary-800: #1E2024;
    --primary-900: #050506;
  
    --accent-100: #FBDCA8;
    --accent-200: #FACD81;
    --accent-300: #F8BD59;
    --accent-400: #F7AE32;
    --accent-500: #F59E0B;
    --accent-600: #C07C08;
    --accent-700: #8A5906;
    --accent-800: #543603;
    --accent-900: #1E1401;
  
    --secondary-100: #e6fffa;
    --secondary-200: #b2f5ea;
    --secondary-300: #81e6d9;
    --secondary-400: #4fd1c5;
    --secondary-500: #38b2ac;
    --secondary-600: #319795;
    --secondary-700: #2c7a7b;
    --secondary-800: #285e61;
    --secondary-900: #234e52;
  
    --neutral-100: #f7fafc;
    --neutral-200: #edf2f7;
    --neutral-300: #e2e8f0;
    --neutral-400: #cbd5e0;
    --neutral-500: #a0aec0;
    --neutral-600: #718096;
    --neutral-700: #4a5568;
    --neutral-800: #2d3748;
    --neutral-900: #1a202c;
  
    --light-100: #ffffff;
  }