.slider {
    position: static;
}
.slider__container {
    position: relative;
    padding-bottom: 40px; 
    overflow: hidden;
}
.slider__container--fit {
    width: fit-content;
}
.slider__container--with-thumbs {
    display: grid;
    grid-template-columns: 1fr 5fr;
    gap: 25px;
    height: 480px;
}
.slider__slides {
    position: relative;
    overflow: hidden;
    padding: 0 20px;
}
.slider__thumbs {
    position: relative;
    box-sizing: border-box;
    height: 480px;
    width: 80px;
}
.slider__bullet--active {
    background: gray;
    opacity: 1;
}
.slider__thumb {
    height: 80px;
    width: 80px;
}

.slider__container {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }
  .slider__container .swiper{
    padding: 0px 30px;
  }

  .custom_slider__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.custom_slider__container .swiper-button-prev, .custom_slider__container .swiper-button-next {
    position: unset;
    width: 40px;
    height: 40px;
    z-index: 9;
    background: #D9D9D9;
    border: 1px solid gray;
    border-radius: 50%;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
    outline: 0;
    transition: background-color .2s ease, color .2s ease;
    flex-shrink: 0;
  }

  .custom_slider__container .swiper-button-prev::after, .custom_slider__container .swiper-button-next::after{
    font-size: 20px;
}

.custom_slider__container .swiper-button-prev:after {
        position: relative;
        left: -1px;
  }

  .custom_slider__container .swiper-button-next:after {
        position: relative;
        left: 1px;
  }

  .custom_slider__container .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    cursor: auto;
    pointer-events: none;
  }

  .custom_slider__container .slider__bullet--active{
    background: #043C63;
    opacity: 1;
  }

  .custom_slider__container .swiper-slide a{
    display: flex;
    flex-direction: column;
    align-items: center;
  }


 .custom-slider.slider-mini a img{
  width: 125px;
  height: auto;
 }

 .custom-slider.slider-normal a img{
  width: 180px;
  height: auto;
 }

 .save-for-later-slider .swiper-slide img{
  width: 125px;
  height: auto;
 }

 .save-for-later-slider .sfl-product-name{
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
 }

 .save-for-later-slider .swiper-slide .additionalServices img{ width: 36px; height: 36px;}