.default {
    --primary-100: #666e82;
    --primary-200: #515971;
    --primary-300: #3b455f;
    --primary-400: #25304d;
    --primary-500: #192038;
    --primary-600: #1e263e;
    --primary-700: #1a2236;
    --primary-800: #161d2e;
    --primary-900: #131827;
  
    --accent-100: #1a4c78;
    --accent-200: #154875;
    --accent-300: #12497a;
    --accent-400: #054178;
    --accent-500: #043461;
    --accent-600: #032a4f;
    --accent-700: #02203d;
    --accent-800: #01172b;
    --accent-900: #010e1a;
  
  
    --secondary-100: #e6fffa;
    --secondary-200: #b2f5ea;
    --secondary-300: #81e6d9;
    --secondary-400: #4fd1c5;
    --secondary-500: #38b2ac;
    --secondary-600: #319795;
    --secondary-700: #2c7a7b;
    --secondary-800: #285e61;
    --secondary-900: #234e52;
  
    --neutral-100: #f7fafc;
    --neutral-200: #edf2f7;
    --neutral-300: #e2e8f0;
    --neutral-400: #cbd5e0;
    --neutral-500: #a0aec0;
    --neutral-600: #718096;
    --neutral-700: #4a5568;
    --neutral-800: #2d3748;
    --neutral-900: #1a202c;
  
    --light-100: #ffffff;
  }