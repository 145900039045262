@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --blue-900: #043c63;
  --red-100: #fce7e7;
  --red-900: #da0f0f;
  --gray-900: #333333;
  --gray-700: #ededed;
  --gray-600: #999999;
  --gray-500: #555555;
  --gray-800: #666666;
  --green-900: #268936;
}

@import './themes/default.css';
@import './themes/theme1.css';
@import './themes/theme2.css';
@import './themes/theme3.css';

html {
  @apply p-0; /* For tailwind Dialog styling override */
}

body {
  color: #666666;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

/* This style corresponds to
 * the footer component due to
 * a bug in TailwindCSS not
 * recognizing template columns
 * with more than 4 columns.
 * see components/commercetools-ui/footer/index.tsx
 * This is a hardcoded and messy
 * workaround.
 */
@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
input[type='checkbox'],
input[type='checkbox']:focus {
  width: 20px;
  height: 20px;
  border-color: #999;
  outline: none;
  outline-offset: 0em;
  --tw-ring-color: transparent;
  color: transparent;
}

input[type='checkbox']:checked {
  background-image: url(../components/images/checkboxCheck.png);
  border-color: transparent;
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  outline: 1px solid #999;
}
input[type='checkbox']:checked:hover {
  border-color: transparent;
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

.select {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 11px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
}

@layer base {
  html {
    font-family: 'Lato', sans-serif;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number']:focus {
  outline: none;
  border: 1px transparent;
}

@layer utilities {
  .relative-width {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fixed-screen-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .markdown {
    @apply max-w-none overflow-x-hidden;
    @apply mx-auto pb-10 text-neutral-800 prose-h1:text-neutral-800 prose-h2:text-neutral-800 prose-h3:text-neutral-800 prose-h4:text-neutral-800 lg:prose-lg;
    @apply prose-a:text-accent-600 prose-blockquote:text-neutral-700;
    @apply prose-code:rounded prose-code:bg-neutral-200 prose-code:p-1 prose-code:text-accent-600 prose-pre:bg-neutral-200;
    @apply prose-img:rounded-sm;
    @apply prose-strong:font-bold prose-strong:text-neutral-800;
    @apply prose-ol:list-decimal;
    @apply prose-ul:list-disc;
    @apply prose-li:text-neutral-800;
  }
}

.custom-box-shadow {
  -webkit-box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.15);
}

.custom-box-shadow-top-left {
  -webkit-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

input[type='radio'],
input[type='radio']:focus {
  color: #fff;
  border: 1px solid #666;
  outline: none;
  outline-offset: 0em;
  --tw-ring-color: transparent;
  border-radius: 50%;
}
input[type='radio']:checked {
  background-image: url('../components/images/radio-bg.png');
  background-position: 50% 50%;
  background-size: 58%;
  outline: 1px solid #666;
}
input[type='radio']:disabled {
  border: 1px solid #9ca3af;
}

@media (max-width: 1023px) {
  input[type='radio']:checked {
    outline: none;
    border: 1px solid #666;
    border-radius: 50%;
  }
}

div.swiper-button-next,
div.swiper-button-prev {
  display: none;
}

.checkout-shadow {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  font-size: 1em;
  line-height: 1.125em;
}
.pagination {
  display: flex;
  justify-content: center;
}
.pagination li:not(.break) a {
  display: block;
  border-radius: 2px;
  width: 24px;
  height: 24px;
  line-height: 20px;
  color: #043c63;
  border: 1px solid #043c63;
  text-align: center;
}
.pagination li {
  margin: 0 2px;
}
.pagination li.selected a {
  background-color: #333;
  border: 1px solid #333;
  color: #fff;
}
.pagination li.disabled a {
  cursor: default;
  opacity: 0.5;
}
.pagination li.disabled {
  visibility: hidden;
}
.pagination li.next a,
.pagination li.previous {
  position: relative;
}
.pagination li.next a:after,
.pagination li.previous a:after {
  position: absolute;
  background: url('../components/images/page-arrow.png') center center no-repeat;
  content: '';
  width: 8px;
  height: 12px;
  top: 5px;
  left: 8px;
}

.pagination li.previous a:after {
  transform: rotate(-180deg);
}

.customCheckBox input[type='checkbox']:checked {
  background: none;
}
.checked-icon {
  display: none;
}

.filterNavCheckbox:checked ~ .checked-icon {
  display: block;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  border: 1px solid var(--gray-900);
  background-color: white;
  color: var(--gray-900);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext.tooltip-wide-left {
  width: 200px;
  left: unset;
  right: 20px;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: calc(100% - 1px);
  left: 50%;
  margin-left: -5px;
  border-width: 0px 10px 10px 10px;
  border-style: solid;
  border-color: white transparent;
}

.tooltip .tooltiptext::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 0px 10px 10px 10px;
  border-style: solid;
  border-color: #555 transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.item-tag.sale {
  background-color: #da0f0f;
}
.item-tag.popular {
  background-color: #0066cc;
}
.item-tag.newItem {
  background-color: #44a613;
}
.item-tag.ourPick {
  background-color: #e88911;
}

.slider__container .swiper {
  max-width: 100%;
  padding: 0px 0px 40px 0px !important;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  position: absolute;
  right: 0;
  height: 100%;
  width: 20px;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}

/*Multizoom css starts here*/

.magnifyarea {
  /* CSS to add shadow to magnified image. Optional */
  box-shadow: 5px 5px 7px #818181;
  -webkit-box-shadow: 5px 5px 7px #818181;
  -moz-box-shadow: 5px 5px 7px #818181;
  filter: progid:DXImageTransform.Microsoft.dropShadow(color=#818181, offX=5, offY=5, positive=true);
  background: white;
}

.magnifyarea img {
  max-width: unset;
}

.targetarea {
  /* CSS for container div(s) of the zoomable image */
  width: 325px; /* wide or wider than the widest zoomable image */
  height: 338px; /* high or higher than the tallest zoomable image */
  margin-top: 3px;
}

#two {
  /* Added CSS for second target div of zoomable images */
  height: 243px; /* high or higher than the tallest zoomable image */
}

.targetarea img {
  /* zoomable image */
  margin: auto; /* for horizontal centering */
  display: block; /* also for horizontal centering */
  position: relative; /* along with on the fly calculations in script, for vertical centering */
  border-width: 0;
}

.thumbs {
  /* divs holding the trigger links - styles optional, used here to center their links below their respective zoomable image */
  padding-top: 25px;
  width: 325px;
  text-align: center;
}

.thumbs a {
  /* trigger links on the thumbnail images */
  text-decoration: none; /* avoid underlines of images, text or spaces in these links */
}

.thumbs img {
  /* trigger images - the thumbnails used to load new zoomable images into the targetarea */
  border-width: 0; /* avoid default borders in some browsers */
}

a:hover {
  text-decoration: underline;
}

#description,
#description2 {
  position: absolute; /* required for description folows image bottom (descpos: true) */
  width: 325px; /* should be width of zoomable image container (.targetarea) */
  text-align: center;
  font: bold 95% sans-serif;
  margin-top: 3px; /* when following image bottom, this sets a fixed distance for that */
  color: #222;
  background-color: #fff;
}

/*Multizoom css ends here*/
.line-through-45deg {
  position: relative;
}

.line-through-45deg::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -24%;
  width: 145%;
  height: 2px;
  background-color: #000000;
  transform: rotate(45deg);
}

.search-suggestions {
  position: absolute;
  width: 100%;
  background: white;
  top: calc(100% + 5px);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-height: 480px;
  z-index: 9999;
}

@media (min-width: 1023px) {
  .orderdetail-itemcontainer {
    grid-template-columns: 2fr 1fr 1.5fr;
  }
}
.st-inline-share-buttons {
  z-index: 0 !important;
}
.nonShippablezips-grid {
  column-count: 7;
}

.nonShippablezips-grid-column {
  column-count: 3;
}

/*shareit css starts*/
.sharethis-inline-share-buttons-aafes {
  display: flex !important;
  justify-content: space-evenly;
  width: 100%;
}
.sharethis-inline-share-buttons-aafes-mp {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}
.sharethis-inline-share-buttons-aafes .st-btn,
.sharethis-inline-share-buttons-aafes-mp .st-btn,
.sharethis-inline-follow-buttons-aafes .st-btn {
  margin: 0;
  display: inline-block !important;
}
.sharethis-inline-follow-buttons-aafes .st-top {
  display: none;
}
.sharethis-inline-follow-buttons-aafes .st-btn {
  margin-left: 0.5rem;
}
.sharethis-inline-share-buttons-aafes .st-btn img,
.sharethis-inline-share-buttons-aafes-mp .st-btn img,
.sharethis-inline-follow-buttons-aafes .st-btn img {
  filter: invert(82%) sepia(9%) saturate(4609%) hue-rotate(179deg) brightness(94%) contrast(92%);
}
@media (min-width: 767px) {
  iframe,
  .adsbygoogle,
  .adsbygoogle div {
    width: 100% !important;
  }
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  scrollbar-width: none;
}
  .left-nav-scrollbar::-webkit-scrollbar {
  width: 11px;
  }
  .left-nav-scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border: 1px solid rgb(136, 136, 136);
  border-radius: 4px;
  }
  .left-nav-scrollbar::-webkit-scrollbar-track-piece {
  background: rgb(214, 214, 214);
  border-left: 5px solid rgb(255, 255, 255);
  border-right: 5px solid rgb(255, 255, 255);
  }
.cart_savefor_later a.sfl-product-name {
  display: block !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.aafes-swatch {
  background: transparent none 0 0 cover no-repeat;
}

.aafes-swatch.black {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_black');
}

.aafes-swatch.blue {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_blue');
}

.aafes-swatch.brown {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_brown');
}

.aafes-swatch.camouflage {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_camouflage');
}

.aafes-swatch.gold {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_gold');
}

.aafes-swatch.gray {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_gray');
}

.aafes-swatch.green {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_green');
}

.aafes-swatch.ivory {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_ivory');
}

.aafes-swatch.lime {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_lime');
}

.aafes-swatch.multi {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_multicolor');
}

.aafes-swatch.multicolor {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_multicolor');
}

.aafes-swatch.nocolor {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_nocolor');
}

.aafes-swatch.olive {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_olive');
}

.aafes-swatch.orange {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_orange');
}

.aafes-swatch.pink {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_pink');
}

.aafes-swatch.purple {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_purple');
}

.aafes-swatch.red {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_red');
}

.aafes-swatch.silver {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_silver');
}

.aafes-swatch.tan {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_tan');
}

.aafes-swatch.violet {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_violet');
}

.aafes-swatch.white {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_white');
}

.aafes-swatch.yellow {
  background-image: url('https://media.shopmyexchange.com/is/image/aafes/icon_yellow');
}

/*shareit css ends*/

/*--Loader CSS Animation--*/
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  display: inline-block;
  animation: bblFadInOut 1.8s infinite ease-in-out;
  animation-fill-mode: both;
  top: -2.5em;
}
.loader {
  color: #000;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.aafes-orderList ul {
  list-style-type: disc;
  padding-left: 20px;
}

.inverted-tooltip {
  position: relative;
}

.inverted-tooltip .inverted-tooltiptext {
  visibility: hidden;
  border: 1px solid var(--gray-900);
  background-color: white;
  color: var(--gray-900);
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 10px);
  left: 20%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.inverted-tooltip-wide-left .inverted-tooltiptext {
  width: 200px;
  left: unset;
  right: 20px;
}

.inverted-tooltip .inverted-tooltiptext::after {
  content: '';
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  margin-left: -5px;
  border-width: 10px 10px 0 10px; 
  border-style: solid;
  border-color: white transparent; 
}

.inverted-tooltip .inverted-tooltiptext::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 10px 10px 0 10px; 
  border-style: solid;
  border-color: var(--gray-900) transparent; 
}

.inverted-tooltip:hover .inverted-tooltiptext {
  visibility: visible;
  opacity: 1;
}

#reviews[data-loggedin = "false"] .bv-write-review-container , #reviews[data-loggedin = "false"] button#first-to-write{
  display: none !important;
  }

/* Auto Suggestion Styles Start*/
.arrow_btn_st {
  position: relative;
  margin: 12px 18px 12px 20px;
  height: 22px;
  width: 26px;
  vertical-align: middle;
  box-shadow: none;
  padding: 0px;
  border: 0px;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  overflow: visible;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.arrow_btn_st::after {
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3C!DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20%20viewBox%3D%220%200%2032%2032%22%20enable-background%3D%22new%200%200%2032%2032%22%20xml%3Aspace%3D%22preserve%22%3E%3Cg%3E%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23888888%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20x1%3D%223%22%20y1%3D%223%22%20x2%3D%2229%22%20y2%3D%2229%22%2F%3E%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23888888%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20x1%3D%223%22%20y1%3D%223%22%20x2%3D%223%22%20y2%3D%2224%22%2F%3E%20%20%20%3Cline%20fill%3D%22none%22%20stroke%3D%22%23888888%22%20stroke-width%3D%223%22%20stroke-linecap%3D%22round%22%20stroke-miterlimit%3D%2210%22%20x1%3D%2224%22%20y1%3D%223%22%20x2%3D%223%22%20y2%3D%223%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  content: "";
  background-size: 17px 17px;
  background-position: right center;
  height: 22px;
  width: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.search_prod_display {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 90px 1fr;
  max-height: 84px;
}
.display_name_over_flow {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.suggestion_option_icon {
  margin-right: 20px;
  display: inline-block;
  /* background-image: url(https://www.shopmyexchange.com/ux-responsive/images/icon-search.svg); */
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  position: relative;
  border: 0px;
  background-color: transparent;
}
@media screen and (max-width: 1080px){
.suggestion_option_icon {
    /* left: 160px; */
    width: 16px;
    height: 16px;
    margin-right: 0px;
}
}
.aafes-loading {
  background-color: white;
  opacity: 0.3;
  pointer-events: none;
  min-height: 60px;
}
.aafes-loading:after {
  content: url(https://www.shopmyexchange.com/ux-responsive/images/loading-indicator.gif);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
  z-index: 100000;
}

.register-form input:-webkit-autofill:disabled,
.register-form input:-webkit-autofill:disabled:hover, 
.register-form input:-webkit-autofill:disabled:focus, 
.register-form input:-webkit-autofill:disabled:active{
    -webkit-box-shadow: 0 0 0 30px#999999 inset !important;
    color: rgb(0, 0, 0) ;
}
.register-form input:disabled{
  color: rgb(0, 0, 0) ;
}
#BVRRSummaryContainer .bv-cv2-cleanslate .bv-shared .bv-compat .bv-prs-container .bv-primarySummary-rating-container .bv-summary-bar-minimalist-horizontal .bv-stars-container{
  margin-right: 0px !important;
}


/* Auto Suggestion Styles End*/